import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resultArea"]

  // ボタンクリック時に呼ばれる
  toggleContent(e) {
    const button = e.currentTarget
    const eventId = button.dataset.eventContentEventIdValue

    // resultArea DOM要素を取得
    const resultArea = document.getElementById(`result-area-${eventId}`)

    // (A) もしまだロードしていないならAjaxでfetch
    if (resultArea.dataset.loaded === "false") {
      this.fetchContent(eventId)
        .then(html => {
          // HTMLを挿入
          resultArea.innerHTML = html
          // ロード済みに設定
          resultArea.dataset.loaded = "true"
          // 表示
          resultArea.style.display = "block"
        })
        .catch(err => console.error("Error fetchContent:", err))
    } else {
      // (B) すでに取得済み → 表示中なら非表示, 非表示なら表示
      if (resultArea.style.display === "none") {
        // 非表示→表示
        resultArea.style.display = "block"
      } else {
        // 表示→非表示
        resultArea.style.display = "none"
      }
    }
  }

  // Ajaxで /events/:id/content を取得
  fetchContent(eventId) {
    return fetch(`/events/${eventId}/content`, { method: "GET" })
      .then(res => res.text())
  }
}
