import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items", "currentDay"]

  connect() {
    this.index = 0
    // ① 初期スライドの日付と高さを合わせる
    this.updateSlider()
  }

  prevDay() {
    if (this.index > 0) {
      this.index--
      this.updateSlider()
    }
  }

  nextDay() {
    if (this.index < this.lastIndex) {
      this.index++
      this.updateSlider()
    }
  }

  updateSlider() {
    // 横方向移動
    const percentage = this.index * 100
    this.itemsTarget.style.transform = `translateX(-${percentage}%)`

    // アクティブなスライド要素を取得
    const activeSlide = this.itemsTarget.children[this.index]

    // (A) スライドの高さを計測
    // const newHeight = activeSlide.scrollHeight
    const eventCards = activeSlide.querySelectorAll(".card-box")
    const eventCount = eventCards.length
    // const cardHeight = 250
    const cardHeight = window.innerWidth <= 1024 ? 110 : 230;
    const newHeight = 150 + cardHeight * eventCount

    // (B) 親 .slider-container に反映
    // this.element.style.height = `${newHeight}px`
    this.element.style.height = `${newHeight}px`

    // 日付表示を更新
    this.updateDayLabel()
  }

  updateDayLabel() {
    // アクティブスライドの data-slider-day-value 取得
    const activeSlide = this.itemsTarget.children[this.index]
    const dayString = activeSlide.dataset.sliderDayValue || ""
    // currentDayTarget にテキスト表示
    if (this.hasCurrentDayTarget) {
      this.currentDayTarget.textContent = dayString
    }
  }

  get lastIndex() {
    return this.itemsTarget.children.length - 1
  }
}
