import { Controller } from "@hotwired/stimulus"

// Ajaxによる検索を担当するコントローラ
export default class extends Controller {
  static targets = ["result", "hallName", "prefectureId", "targetDate"]
  static values = { url: String }

  connect() {
  }

  // 店舗名アイコンクリック
  searchByName() {
    this.sendRequest()
  }

  // 都道府県セレクト変更
  searchByPrefecture() {
    this.sendRequest()
  }

  // 日付セレクト変更
  searchByDate() {
    this.sendRequest()
  }

  sendRequest() {
    // 3つの条件
    const hallName = this.hallNameTarget.value || ""
    const prefectureId = this.prefectureIdTarget.value || ""
    const dateVal = this.targetDateTarget.value || ""

    // fetch で POST or GET
    // ここでは例として POST
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaValue('csrf-token')
      },
      body: JSON.stringify({
        hall_name: hallName,
        prefecture_id: prefectureId,
        target_date: dateVal
      })
    })
    .then(res => res.text())
    .then(html => {
      // レスポンス (部分HTML) を .resultTarget に入れ替え
      this.resultTarget.innerHTML = html
      // Stimulus再接続 → slider_controller等があれば読み込み
    })
    .catch(e => console.error('search error:', e))
  }
}

function getMetaValue(name) {
  const element = document.querySelector(`meta[name="${name}"]`)
  return element && element.getAttribute("content")
}
